/**
 * Import all of Bootstrap's JS:
 ********************************************************************/

import * as bootstrap from "bootstrap";

/**
 * Import:
 ********************************************************************/

import { fetchDrinksRecords } from "./db.js";

/**
 * Toast:
 ********************************************************************/

const toastTrigger = document.getElementById("toastbtn");
const toast = document.getElementById("toast");
const btn = document.getElementById("disable-btn");

if (toastTrigger) {
  const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toast);
  toastTrigger.addEventListener("click", () => {
    toastBootstrap.show();
    // Disable button on click:
    btn.classList.add("disabled");
  });
}

/**
 * Search:
 ********************************************************************/

let form, submitButton, input;
form = document.getElementById("search-form");
input = document.getElementById("drink-input");
submitButton = document.getElementById("submit-button");

export function searchForm(DrinksList, listDrinks) {
  function submit() {
    const FilteredList = DrinksList.filter((drink) => {
      if (drink["id"] === undefined) return false;
      const name = drink["name"];
      const tags = drink["tags"];
      const reg = new RegExp(input.value, "i");

      return name.match(reg) || tags.some((tag) => tag.match(reg));
    });
    listDrinks(FilteredList);
  }

  form.addEventListener("keypress", function (event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  });

  form.addEventListener("keyup", function (event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  });

  submitButton.addEventListener("click", () => submit());
}

/**
 * Clear Component:
 ********************************************************************/

export function clearComponent(component) {
  let child = component.lastElementChild;

  while (child) {
    component.removeChild(child);
    child = component.lastElementChild;
  }
}

/**
 * Recipes:
 ********************************************************************/

const drinkContainer = document.getElementById("drink-container");

let dbList;

async function listDrinks(DrinksList) {
  clearComponent(drinkContainer);

  if (!dbList) {
    dbList = await fetchDrinksRecords();
    DrinksList = Object.assign({}, dbList);
  }
  searchForm(dbList, listDrinks);

  for (const key in DrinksList) {
    const drink = DrinksList[key];

    if (drink["id"] === undefined) {
      continue;
    }

    // Elements for each piece of the card
    const drinkCard = document.createElement("div");
    drinkCard.classList.add(
      "card-style-size",
      "bg-dark",
      "w-100",
      "h-110",
      "rounded-2",
      "overflow-hidden",
      "mb-2",
      "position-relative"
    );
    drinkCard.addEventListener("click", () => {
      displayRecipe(drink);
    });
    drinkCard.setAttribute("data-bs-toggle", "offcanvas");
    drinkCard.setAttribute("data-bs-target", "#recipe-container");
    drinkCard.setAttribute("aria-controls", "recipe-container");
    drinkContainer.appendChild(drinkCard);
    // Card structure
    const drinkImg = document.createElement("div");
    drinkImg.style.backgroundImage = `url(${drink.image[0].url})`;
    drinkImg.classList.add(
      "drink-card-image",
      "rectangle-bg-image",
      "w-70",
      "h-100",
      "float-start"
    );
    drinkCard.appendChild(drinkImg);

    const drinkTop = document.createElement("div");
    drinkTop.classList.add(
      "drink-top",
      "d-block",
      "justify-content-between",
      "my-2"
    );
    drinkCard.appendChild(drinkTop);

    const drinkTitle = document.createElement("h4");
    drinkTitle.textContent = drink.name;
    drinkTitle.classList.add(
      "drink-card-title",
      "d-inline",
      "text-light",
      "fs-20",
      "w-100"
    );
    drinkTop.appendChild(drinkTitle);

    const drinkDiff = document.createElement("span");
    drinkDiff.textContent = " ";
    drinkDiff.classList.add(
      "drink-difficulty",
      "badge",
      "mx-2",
      "mt-1",
      "col",
      "center",
      "fs-15"
    );
    drinkTop.appendChild(drinkDiff);

    const drinkTagsList = document.createElement("div");
    drinkTagsList.classList.add("drink-tags");
    drinkCard.appendChild(drinkTagsList);

    drink.tags.forEach((tagName) => {
      const tag = document.createElement("span");
      tag.textContent = tagName;
      tag.classList.add("badge", "bg-success", "mx-1");

      drinkTagsList.appendChild(tag);
    });

    const drinkBodyText = document.createElement("span");
    drinkBodyText.classList.add("drink-card-body");
    drinkCard.appendChild(drinkBodyText);

    for (let i = 0; i < DrinksList.length; i++) {
      const drinkBadge = DrinksList[i];
    }

    //change difficult numbers to words

    if (drink.difficulty < 3) {
      drinkDiff.classList.add("bg-green");
    } else if (drink.difficulty < 5) {
      drinkDiff.classList.add("bg-warning");
    } else if (drink.difficulty < 6) {
      drinkDiff.classList.add("bg-danger");
    }

    const textNode = document.createTextNode(drink.name);
    const ingredientList = drink.ingredients;
  }
}

listDrinks();

/**
 * Recipe Container:
 ********************************************************************/

const recipeContainer = document.getElementById("recipe-container");

function appendNewNode(child = "div", classes = [], input = "", parent = this) {
  if (typeof child === "string") {
    child = document.createElement(child);
  }
  if (typeof parent === "string") {
    parent = document.createElement(parent);
  }
  if (classes.length > 0) child.classList.add(...classes);
  if (typeof input === "string") {
    const text = document.createTextNode(input);
    child.appendChild(text);
  }
  if (Array.isArray(input)) {
    input.forEach((element) => {
      let item = parent.appendNewNode(child.nodeName, classes, element);
      return item;
    });
  }
  if (parent instanceof Node && child instanceof Node) {
    parent.appendChild(child);
    return child;
  } else {
    console.log("ERR: Parent is not of Node type");
  }
}

function getDifficulty(difficulty) {
  if (difficulty < 3) {
    return "Easy";
  } else if (difficulty < 5) {
    return "Medium";
  } else {
    return "Hard";
  }
}

Node.prototype.appendNewNode = appendNewNode;

export function displayRecipe(drink) {
  document.getElementById("title").innerText = drink.name;

  document.getElementById(
    "square-bg-image"
  ).style.backgroundImage = `url(${drink.image[0].url})`;

  document.getElementById("difficulty").innerText = getDifficulty(
    drink.difficulty
  );

  document.getElementById("servings").innerText = `${drink.servings} servings`;

  const ingredients = document.getElementById("ingredients");
  clearComponent(ingredients);

  drink.ingredients.forEach((ingredient) => {
    let form = ingredients.appendNewNode("div", ["form-check"]);
    const checkbox = form.appendNewNode("input", ["form-check-input"]);
    checkbox.type = "checkbox";
    checkbox.value = "";
    checkbox.id = ingredient.name;
    const label = form.appendNewNode(
      "label",
      ["form-check-label"],
      `${ingredient.name.toLowerCase()} (${ingredient.count} ${
        ingredient.measure
      })`
    );
    label.for = ingredient.name;
    ingredients.appendNewNode(
      "p",
      ["fst-italic"],
      ingredient.substitutes ? `or ${ingredient.substitutes.join(", ")}` : ""
    );
  });

  const tools = document.getElementById("tools");
  clearComponent(tools);

  drink.tools.forEach((tool) => {
    const form = tools.appendNewNode("div", ["form-check"]);
    const checkbox = form.appendNewNode("input", ["form-check-input"]);
    checkbox.type = "checkbox";
    checkbox.value = "";
    checkbox.id = tool;
    const label = form.appendNewNode(
      "label",
      ["form-check-label"],
      tool.toLowerCase()
    );
    label.for = tool;
  });

  document.getElementById("shop-button").onclick = function () {
    location.href = "./store.html";
  };

  const stepsList = document.getElementById("steps-list");
  clearComponent(stepsList);

  drink.steps.split("\n").forEach((step) => {
    if (step.length > 0) {
      if (step.match(/^\d/)) {
        stepsList.appendNewNode("li", [], step.slice(3));
      } else {
        stepsList.appendNewNode("span", [], step);
      }
    }
  });
}

/**
 * Store:
 ********************************************************************/

import { fetchProductRecords } from "./db.js";

async function listProducts() {
  const data = await fetchProductRecords();
  const ProductsList = data;
  for (let id in ProductsList) {
    // for every key in the object

    if (!ProductsList[id].name) continue; // avoid the count property

    const card = document.createElement("div");
    card.classList.add("card", "product", "bg-dark", "text-light");

    const linkBody = document.createElement("a");
    linkBody.href = ProductsList[id].link;
    linkBody.target = "_blank";
    linkBody.classList.add("btn", "bg-dark", "text-light");
    card.appendChild(linkBody);

    const imageContainer = document.createElement("div");
    linkBody.appendChild(imageContainer);
    imageContainer.classList.add(
      "d-flex",
      "justify-content-center",
      "bg-white",
      "border",
      "border-secondary",
      "rounded",
      "p-3",
      "mt-1"
    );
    imageContainer.style.height = "200px";

    const image = document.createElement("img");
    image.src = ProductsList[id].image;
    imageContainer.appendChild(image);

    const cardBody = document.createElement("div");
    linkBody.appendChild(cardBody);
    cardBody.classList.add("card-body");

    const cardTitle = document.createElement("h5");
    cardBody.appendChild(cardTitle);
    cardTitle.style.textAlign = "center";

    const titleText = document.createTextNode(
      ProductsList[id].name.toUpperCase()
    );
    cardTitle.appendChild(titleText);

    cardTitle.classList.add("card-title");

    const storeContainer = document.getElementById("store-container");
    storeContainer.appendChild(card);
  }
}

listProducts();
