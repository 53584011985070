/**
 * Airtable DB:
 ********************************************************************/

import Airtable from "airtable";

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.AIRTABLE_API_TOKEN,
});

const base = new Airtable.base("appQme2sgEG7CYuo4");

const DRINKS_DATABASE_TABLE = "Cocktails";
const PRODUCTS_DATABASE_TABLE = "Products";

class DrinksList {}
DrinksList.prototype.count = 0;
DrinksList.prototype.filter = function filter(cb) {
  let drinks = new DrinksList();
  for (let id in this) {
    let value = this[id];
    if (value["name"] && cb(value, id, this)) {
      drinks[id] = value;
    }
  }
  return drinks;
};

export function fetchDrinksRecords() {
  console.log(`Fetching drinks from ${DRINKS_DATABASE_TABLE}`);
  const drinks = new DrinksList();
  const table = base(DRINKS_DATABASE_TABLE);
  return table
    .select({
      fields: [
        "name",
        "image",
        "servings",
        "difficulty",
        "ingredients",
        "tools",
        "tags",
        "steps",
      ],
    })
    .all()
    .then((records) => {
      records.forEach(function (record, i) {
        let name;
        try {
          name = record.get("name");
          if (name) {
            let ingredients = JSON.parse(record.get("ingredients"));
            let id = String(i).padStart(4, "0");
            drinks[id] = {
              id,
              name,
              image: record.get("image"),
              servings: record.get("servings"),
              difficulty: record.get("difficulty"),
              ingredients,
              tools: JSON.parse(record.get("tools")),
              tags: JSON.parse(record.get("tags")),
              steps: record.get("steps"),
            };
            drinks.count += 1;
          }
        } catch (err) {
          console.log(`Could not load ${name}: ${err}`);
          if (drinks.error === undefined) drinks.error = 0;
          drinks.error += 1;
        }
      });
      return drinks;
    });
}

class ProductsList {}
ProductsList.prototype.count = 0;

export function fetchProductRecords() {
  const products = new ProductsList();
  const table = base(PRODUCTS_DATABASE_TABLE);

  return table
    .select({
      fields: ["name", "image", "link"],
    })
    .all()
    .then((records) => {
      records.forEach(function (record, i) {
        if (record.get("name")) {
          let id = String(i).padStart(4, "0");
          products[id] = {
            id,
            name: record.get("name"),
            image: record.get("image"),
            link: record.get("link"),
          };
          products.count += 1;
        }
      });
      return products;
    });
}
